<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="close()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Recibo de Caja No. {{ data.code }}</v-toolbar-title>
        </v-toolbar>
        <v-container>
          <v-skeleton-loader
            :loading="loading"
            type="list-item-avatar, divider, list-item-three-line, card-heading, image"
          >
            <v-row>
              <v-col cols="12" md="12">
                <v-card>
                  <v-card-title>
                    Datos Recibo de Caja
                    <v-spacer></v-spacer>
                    <v-btn
                      v-if="!data.is_annulment"
                      class="mx-2"
                      fab
                      dark
                      color="indigo"
                      @click="getPDF"
                    >
                      <v-icon dark>mdi-printer</v-icon>
                    </v-btn>
                  </v-card-title>
                  <v-card-text class="text--primary">
                    <p>
                      <strong>No.: </strong>
                      {{ data.code }}
                    </p>
                    <p>
                      <strong>Fecha: </strong>
                      {{ data.date | formatDate }}
                    </p>
                    <p>
                      <strong>Sucursal: </strong>
                      {{ data.company.name }}
                    </p>
                    <p>
                      <strong>Receptor: </strong>
                      {{ data.customer }}
                    </p>
                    <p>
                      <strong>Total: </strong>
                      Q {{ data.amount | currency }}
                    </p>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <div v-if="!data.is_annulment">
              <span class="caption text--secondary">DETALLE DEL RECIBO</span>
              <v-data-table
                :headers="headerDetails"
                :items="data.detail_cash_receipt"
                hide-default-footer
                class="elevation-1"
              >
                <template v-slot:[`item.amount`]="{ item }"
                  >Q {{ item.amount | currency }}
                </template></v-data-table
              >
              <br />
              <span class="caption text--secondary">DETALLE DEL PAGO</span>
              <v-data-table
                :headers="headers"
                :items="data.payment_cash_receipt"
                hide-default-footer
                class="elevation-1"
              >
                <template v-slot:[`item.payment_type`]="{ item }">
                  <span v-if="item.payment_type == 'PAYE'">EFECTIVO</span>
                  <span v-else-if="item.payment_type == 'PAYD'">DEPÓSITO</span>
                  <span v-else-if="item.payment_type == 'PAYC'">CHEQUE</span>
                </template>
                <template v-slot:[`item.amount`]="{ item }"
                  >Q {{ item.amount | currency }}
                </template>
              </v-data-table>
            </div>
          </v-skeleton-loader>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import requests from "@/services/requests";
import blobResponse from "@/utils/response.blob";
export default {
  props: {
    dialog: { type: Boolean, required: true },
    receipt: { type: Number, required: true },
  },
  data() {
    return {
      loading: false,
      data: {
        customer: undefined,
        amount: undefined,
        code: undefined,
        date: undefined,
        company: [
          {
            id: "",
            name: "",
          },
        ],
        detail_cash_receipt: [],
        payment_cash_receipt: [],
      },
      headers: [
        { text: "No.", value: "code" },
        { text: "Tipo de pago", value: "payment_type" },
        { text: "Referencia", value: "document" },
        { text: "Cantidad", value: "amount" },
      ],
      headerDetails: [
        { text: "Serie", value: "serie" },
        { text: "Número", value: "number" },
        { text: "Concepto", value: "description" },
        { text: "Total Pago", value: "amount" },
      ],
    };
  },
  methods: {
    getDetail() {
      this.loading = true;
      requests.get("/api/cash-receipt/" + this.receipt).then((res) => {
        if (res.status == 200) {
          this.data = res.data;
        } else {
          console.log("error", res);
        }
      });
      this.loading = false;
    },
    close() {
      this.$emit("close");
      this.data = {
        customer: undefined,
        amount: undefined,
        code: undefined,
        date: undefined,
        company: [
          {
            id: "",
            name: "",
          },
        ],
        detail_cash_receipt: [],
        payment_cash_receipt: [],
      };
    },
    getPDF() {
      requests.get("/api/pdf/cash-receipt/" + this.data.id).then((response) => {
        if (response.status == 200) {
          blobResponse(response.data, "application/pdf");
        } else {
          this.snackbarText = "Ha ocurrido un error";
          this.snackbarStatus = true;
        }
      });
    },
  },
  mounted() {
    this.getDetail();
  },
};
</script>